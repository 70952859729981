import {
  Close,
  DownloadOutlined,
  EditOutlined,
  ListOutlined,
  SaveOutlined,
  Send,
  WarningAmber,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import NumericLabel from "react-pretty-numbers";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LightGrayHeading4 from "../../GlobalComponents/GlobalTexts/LightGrayHeading4";
import { OrangeFilledButton, OrangeOutlinedButton } from "../buttons";
import Icons from "../icons";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DisplaySVGImage from "../../GlobalComponents/DisplaySVGImage";
import GrayHeading4 from "../../GlobalComponents/GlobalTexts/GrayHeading4";
import InputComponent from "../../GlobalComponents/InputComponent";
import { add_shopify_details, handleSuccessModal } from "../../actions/actions";
import { radioLabelStyleProps } from "../audienceManager/synchedAudiences/saUpdateFrequencyEdit";
import { redirectToPage } from "../user";
import {
  resetPushAudienceReducer,
  getCustomFilterAudiences,
} from "../../actions/actions";
import SuccessModal from "../../GlobalComponents/SuccessModal";

export const shopifyAdsValue = "shopify";

const orderParams = {
  commafy: true,
  shortFormat: true,
  shortFormatMinValue: 1000,
  justification: "L",
};

const periodList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const CustomSelect = withStyles((theme) => ({
  select: {
    right: 0,
    textAlign: "left",
    border: "1px solid #1e334e",
  },
  icon: {
    top: "calc(50% - 12px)",
    width: "1.5rem",
    height: "1.5rem",
    color: theme.palette.primary.main,
    border: "none",
  },
  outlined: {
    padding: "6px 14px",
    fontSize: "0.875rem",
  },
}))(Select);

const CustomSelect2 = withStyles((theme) => ({
  select: {
    right: 0,
    textAlign: "left",
    border: "1px solid #DAE4ED",
    color: "#A4A4A4",
    borderRadius: 0,
    "&:hover": {
      border: "1px solid #DAE4ED", // Ensure the border color does not change on hover
    },
    "&:focus": {
      border: "1px solid #DAE4ED", // Ensure the border color does not change when focused
    },
  },
  icon: {
    top: "calc(50% - 12px)",
    width: "1.5rem",
    height: "1.5rem",
    color: "#A4A4A4",
    border: "none",
  },
  outlined: {
    padding: "6px 14px",
    fontSize: "0.875rem",
  },
}))(Select);

const CustomInputAdornment = withStyles((theme) => ({
  positionStart: {
    marginRight: 0,
  },
}))(InputAdornment);

const useStyles = makeStyles((theme) => ({
  paperMd: {
    width: 730,
  },
  failuremessage: {
    padding: "12px",
    backgroundColor: "rgba(255,102,92,0.4)",
    borderRadius: 6,
  },
  successmessage: {
    padding: "12px",
    backgroundColor: "rgba(0,128,0,0.4)",
    borderRadius: 6,
  },
  text: {
    fontSize: "0.875rem",
    padding: "10px",
    marginLeft: -5,
  },
  pushButton: {
    borderRadius: "0px 6px 6px 0px",
    fontSize: "1rem",
    fontWeight: 700,
    width: 103,
    marginLeft: 12,
  },
}));

const nameError = "Unable to create Audience List as name is already used.";

const modifyShopifyRenderingTagName = (inputString) => {
  const dateFormat = moment().format("MMDDyyyy");

  let cleanedString;

  if (inputString.includes(`_${dateFormat}`)) {
    cleanedString = inputString.replace(`_${dateFormat}`, ""); // Remove the underscore and date format
  } else {
    cleanedString = inputString; // If date format is not found, keep the string as is
  }

  return cleanedString;
};

const RemarketDialogBox = (props) => {
  const {
    filter_name,
    open,
    close,
    setFieldListName,
    setFieldListName2,
    fieldListName,
    fieldListName2,
    listName2,
    listEdit,
    listSize,
    DownloadHandler,
    list_audiences_reducer,
    handleEditList,
    setListEdit,
    setFinalListName,
    push,
    userProfile,
    schedulerUpdateReplace,
    updateReplacePeriod,
    setUpdateReplacePeriod,
    handleChangeSchedulerUpdateReplace,
    facebookPushAudienceHandler,
    googlePushAudienceHandler,
    push_audiences_reducer,
    handleRadioChange,
    setSchedulerUpdateReplace,
    shopifyPushAudienceHandler,
    // reMarketOption,
    // allGroupData,
  } = props;

  const dispatch = useDispatch();
  const params = useParams();

  localStorage.setItem("navigationBackUrl", window.location.hash);

  const selectedAudienceData = useSelector(
    (state) => state?.fetch_custom_filter_reducer?.selectedAudienceData
  );

  const isRemarketPopUpOpen = useSelector(
    (state) => state?.fetch_custom_filter_reducer?.isRemarketPopUpOpen
  );

  const overViewReducerDataList = useSelector(
    (state) => state?.overview_reducer?.data
  );

  const dataSources = useSelector(
    (state) => state?.businessProfile?.data_sources
  );

  const property_id = useSelector((state) => state?.businessProfile?.bu_id);
  const shopifyLoading1 = useSelector(
    (state) => state?.shopifyIntegrateReducer?.isLoading
  );

  const shopifyLoading2 = useSelector(
    (state) => state?.shopifyAccessTokenReducer?.isLoading
  );

  const isSuccessModalOpen = useSelector(
    (state) => state?.fetch_custom_filter_reducer?.isSuccessModalOpen
  );

  const [allGroupData, setAllGroupData] = useState({});

  const [reMarketOption, setReMarketOption] = useState(true);

  const [shopifyDataSourceObj, setShopifyDataSourceObj] = useState({});
  const [currency, setCurrency] = useState("USD");
  const [region, setRegion] = useState("US");
  const [time_zone, setTimeZone] = useState("GMT");
  const [website_name, setWebsiteName] = React.useState("");
  const [pushSource, setPushSource] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const [checkedState, setCheckedState] = useState(
    userProfile?.ga_client_id
      ? "googleAds"
      : userProfile?.fb_account_id
      ? "metaAds"
      : userProfile?.shopify_shop_url?.length > 0
      ? shopifyAdsValue
      : null
  );
  const [shopifyTagName, setShopifyTagName] = useState(
    modifyShopifyRenderingTagName(fieldListName) || ""
  );
  const paramOptions1 = {
    commafy: true,
    justification: "L",
    shortFormat: true,
    shortFormatMinValue: 10000,
  };
  const handleCheckboxChange = (event) => {
    setCheckedState(event.target.value);
  };

  const handleChangeShopify = (event) => {
    setShopifyTagName(event.target.value);
  };

  useEffect(() => {}, [props]);

  const handlePush = () => {
    if (userProfile.ga_client_id && checkedState === "googleAds") {
      googlePushAudienceHandler();
      setPushSource("googleads");
      setUpdateReplacePeriod(1);
    }
    if (userProfile.fb_account_id && checkedState === "metaAds") {
      facebookPushAudienceHandler();
      setPushSource("facebookads");
      setUpdateReplacePeriod(1);
    }

    if (
      userProfile?.shopify_shop_url &&
      checkedState === shopifyAdsValue &&
      shopifyTagName?.length
    ) {
      const tagName = "lxr_" + shopifyTagName?.trim();

      const obj = {
        schedulerUpdateReplace,
        updateReplacePeriod,
        filter_id: selectedAudienceData?.filter_id,
        filter_name: selectedAudienceData?.filter_name,
        shopify_tag_name: tagName,
      };

      shopifyPushAudienceHandler(obj);
      setPushSource(shopifyAdsValue);
      setUpdateReplacePeriod(1);
    }
  };

  const handleRedirect = () => {
    dispatch(resetPushAudienceReducer());
    setOpenStatus(false);
    dispatch(getCustomFilterAudiences());
    redirectToPage("customers/synced-audiences");
    localStorage.removeItem("selectedAudienceId");
    localStorage.removeItem("selectedAudienceDataOBJ");
  };

  const handleShopifyReAuthenticate = (e) => {
    const data = {
      website_name,
      currency,
      region,
      time_zone,
      store_url: shopifyDataSourceObj?.shop_url,
      re_auth: true,
    };
    localStorage.setItem("source", "shopify");
    localStorage.setItem(
      "selectedAudienceDataOBJ",
      JSON.stringify(selectedAudienceData)
    );
    localStorage.setItem("business_property_id", property_id);
    dispatch(add_shopify_details(e, data));
  };

  const disablePush = () => {
    return (
      !reMarketOption ||
      !userProfile.fb_account_id ||
      !userProfile.ga_client_id ||
      !userProfile.bing_client_id
    );
  };

  useEffect(() => {
    if (selectedAudienceData?.length) {
      setSchedulerUpdateReplace();
    }
  }, []);

  useEffect(() => {
    if (overViewReducerDataList?.length) {
      const allGroupObj = overViewReducerDataList?.filter(
        (each) => each?.segment === "all"
      );

      setAllGroupData(allGroupObj?.[0]);

      if (
        allGroupObj?.[0]?.email_count / allGroupObj?.[0]?.customers ||
        allGroupObj?.[0]?.email_count >= 1000
      ) {
        setReMarketOption(true);
      } else {
        setReMarketOption(false);
      }
    }
  }, [overViewReducerDataList]);

  useEffect(() => {
    if (
      push_audiences_reducer.result &&
      push_audiences_reducer.result.length > 0
    ) {
      setOpenStatus(true);
      const timer = setTimeout(() => {
        dispatch(resetPushAudienceReducer());
        setOpenStatus(false);
        if (push_audiences_reducer.result?.[0]?.message === null) {
          redirectToPage("customers/synced-audiences");
          dispatch(getCustomFilterAudiences());
          close();
          localStorage.removeItem("selectedAudienceId");
          localStorage.removeItem("selectedAudienceDataOBJ");
        }
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [push_audiences_reducer]);

  useEffect(() => {
    if (dataSources?.length) {
      const shopifyArr = dataSources?.filter((each) => {
        if (each?.data_source_type === "shopify") {
          return each;
        }
      });

      if (shopifyArr?.length) {
        const obj = shopifyArr?.[0];
        setShopifyDataSourceObj(obj);
      }
    }
  }, [dataSources]);

  useEffect(() => {
    if (params) {
      const hashString = window.location.hash;
      if (hashString.includes("re-authenticated")) {
        dispatch(
          handleSuccessModal({
            value: true,
            message1: "Re-Authentication Successful",
          })
        );
        setCheckedState(shopifyAdsValue);
      }
    }
  }, [params]);

  return (
    <React.Fragment>
      <Drawer open={open} anchor={"right"}>
        <Box
          // style={{ borderBottom: "1px solid #3333331A", width: "750px" }}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1.5rem 2rem",
            width: "750px",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                gap: 5,
                alignItems: "center",
              }}
            >
              <DisplaySVGImage
                src={Icons?.reMarketIconBlackImage}
                alt={"remarket-icon-black"}
                height={"30px"}
                width={"30px"}
              />
              <LightGrayHeading4
                color="#4E4E4E"
                fontSize="22px"
                lineHeight="26.4px"
              >
                Re-Market
              </LightGrayHeading4>
            </Box>

            <Box style={{ cursor: "pointer" }} onClick={close}>
              <DisplaySVGImage
                src={Icons?.closeIconRedImage}
                alt={"close-icon-red"}
                height={"30px"}
                width={"30px"}
              />
            </Box>
          </Box>
          <Divider />
          <Box
            style={{ display: "flex", flexDirection: "column", gap: "0.6rem" }}
          >
            <GrayHeading4>Selected Audience to Re-Market :</GrayHeading4>

            <LightGrayHeading4
              color={radioLabelStyleProps?.color}
              fontWeight={radioLabelStyleProps?.fontWeight}
              fontSize={radioLabelStyleProps?.fontSize}
              lineHeight={radioLabelStyleProps?.lineHeight}
            >
              {selectedAudienceData?.filter_name || null}
            </LightGrayHeading4>
          </Box>
          {reMarketOption ? (
            userProfile?.bing_client_id ||
            userProfile?.ga_client_id ||
            userProfile?.fb_account_id ? null : (
              <Box
                style={{
                  backgroundColor: "#FFFBF8",
                  border: "1px solid #FFEAD8",
                  width: "750px",
                  display: "flex",
                }}
                p={1}
                py={2}
                pt={2}
                mt={1}
              >
                <WarningAmber
                  style={{
                    color: theme.palette.secondary.light,
                    fontSize: "1.5rem",
                  }}
                />
                <Typography
                  color={"#414141"}
                  style={{ fontSize: "0.875rem", paddingLeft: "5px" }}
                >
                  No Marketing channels are currently connected to this
                  LXRInsights account. To start re-marketing campaigns, please
                  link it to at least one channel via{" "}
                  <Link
                    to={"/connections"}
                    style={{
                      color: theme.palette.secondary.light,
                      fontWeight: 700,
                    }}
                    target="blank"
                  >
                    Connections
                  </Link>
                </Typography>
              </Box>
            )
          ) : (
            <Box
              style={{
                backgroundColor: "#FFFBF8",
                border: "1px solid #FFEAD8",
                width: "750px",
                display: "flex",
              }}
              p={1}
              py={2}
              pt={2}
              mt={1}
            >
              <WarningAmber
                style={{
                  color: theme.palette.secondary.light,
                  fontSize: "1.5rem",
                }}
              />
              {allGroupData?.email_count < 2 ? (
                <Typography
                  color={"#414141"}
                  style={{ fontSize: "0.875rem", paddingLeft: "5px" }}
                >
                  To enable the re-marketing feature, please update your data
                  consent selection to pass customer emails or hashed emails
                  through LXRTracking.{" "}
                  <Link
                    target={"_blank"}
                    to={`/help_center/categories/remarket/61/${"Why is remarketing feature unavailable at the pop-up window?".replace(
                      / /g,
                      "-"
                    )}`}
                    style={{
                      color: theme.palette.secondary.light,
                    }}
                  >
                    View More
                  </Link>
                  . Contact:
                  <a
                    href={"mailto:support@lxrinsights.com"}
                    style={{
                      color: theme.palette.secondary.light,
                    }}
                  >
                    support@lxrinsights.com
                  </a>
                </Typography>
              ) : (
                <Typography
                  color={"#414141"}
                  style={{ fontSize: "0.875rem", paddingLeft: "5px" }}
                >
                  To enable remarketing, at least 1,000 or 50% of your
                  customers' emails are required. Available:
                  {allGroupData?.email_count} (
                  {(
                    (allGroupData?.email_count / allGroupData?.customers) *
                    100
                  ).toFixed(2)}
                  % of{" "}
                  <NumericLabel params={paramOptions1}>
                    {allGroupData?.customers}
                  </NumericLabel>{" "}
                  customers). Contact: 
                  <a
                    href={"mailto:support@lxrinsights.com"}
                    style={{
                      color: theme.palette.secondary.light,
                    }}
                  >
                    support@lxrinsights.com
                  </a>
                </Typography>
              )}
            </Box>
          )}
        </Box>
        {reMarketOption ? (
          userProfile.bing_client_id ||
          userProfile.ga_client_id ||
          userProfile.fb_account_id ||
          userProfile?.shopify_shop_url ? (
            <Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.6rem",
                  padding: "0.2rem 2rem",
                }}
              >
                <GrayHeading4>
                  Select Channel to Push this Audience :
                </GrayHeading4>
                <Box>
                  <Box
                    style={{ display: "flex", alignItems: "center" }}
                    py={1}
                    pt={0}
                  >
                    <RadioGroup
                      value={checkedState}
                      onChange={handleCheckboxChange}
                      row
                    >
                      {userProfile.ga_client_id ? (
                        <FormControlLabel
                          value="googleAds"
                          control={<Radio style={{ color: "#A4A4A4" }} />}
                          label={
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 7,
                              }}
                            >
                              {Icons.googleAdsIcon}

                              <Box marginTop="1px">
                                <LightGrayHeading4
                                  color={"#9A9A9A"}
                                  fontWeight={radioLabelStyleProps?.fontWeight}
                                  fontSize={radioLabelStyleProps?.fontSize}
                                  lineHeight={radioLabelStyleProps?.lineHeight}
                                >
                                  Google Ads
                                </LightGrayHeading4>
                              </Box>
                            </Box>
                          }
                        />
                      ) : null}
                      {userProfile.fb_account_id ? (
                        <FormControlLabel
                          value="metaAds"
                          control={<Radio style={{ color: "#A4A4A4" }} />}
                          label={
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 7,
                              }}
                            >
                              {Icons.metaIcon}

                              <Box marginTop="1px">
                                <LightGrayHeading4
                                  color={"#9A9A9A"}
                                  fontWeight={radioLabelStyleProps?.fontWeight}
                                  fontSize={radioLabelStyleProps?.fontSize}
                                  lineHeight={radioLabelStyleProps?.lineHeight}
                                >
                                  Meta Ads
                                </LightGrayHeading4>
                              </Box>
                            </Box>
                          }
                        />
                      ) : null}

                      {userProfile?.shopify_shop_url ? (
                        <FormControlLabel
                          value={shopifyAdsValue}
                          control={<Radio style={{ color: "#A4A4A4" }} />}
                          label={
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 7,
                              }}
                            >
                              {Icons.shopifyLogoImg}

                              <Box marginTop="1px">
                                <LightGrayHeading4
                                  color={"#9A9A9A"}
                                  fontWeight={radioLabelStyleProps?.fontWeight}
                                  fontSize={radioLabelStyleProps?.fontSize}
                                  lineHeight={radioLabelStyleProps?.lineHeight}
                                >
                                  Shopify
                                </LightGrayHeading4>
                              </Box>
                            </Box>
                          }
                        />
                      ) : null}
                    </RadioGroup>
                  </Box>
                  {checkedState === shopifyAdsValue &&
                  shopifyDataSourceObj &&
                  shopifyDataSourceObj?.write_customers === false ? (
                    <>
                      {/* <Divider /> */}
                      {shopifyLoading1 || shopifyLoading2 ? (
                        <Box
                          style={{
                            height: "20rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress
                            size={50}
                            style={{
                              color: theme.palette.secondary.light,
                            }}
                          />
                        </Box>
                      ) : window?.location?.hash?.includes(
                          "re-authenticated"
                        ) ? null : (
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 5,
                            margin: "0 0 3.5rem 0",
                          }}
                        >
                          <DisplaySVGImage
                            src={Icons?.shopifyWriteAccessImage}
                            alt={"shopifyWriteAccessImage"}
                            height={"250px"}
                          />
                          <GrayHeading4>
                            Required Shopify <b>Edit customers</b> permission.
                          </GrayHeading4>
                          <Box textAlign={"center"} width="25rem">
                            <LightGrayHeading4 fontSize="14px">
                              Please re-authenticate to add LXR tags for
                              customers in Shopify.
                            </LightGrayHeading4>
                          </Box>

                          <Box
                            style={{ cursor: "pointer" }}
                            onClick={handleShopifyReAuthenticate}
                          >
                            <LightGrayHeading4 color="#F58120" fontWeight={700}>
                              Click here to re-authenticate
                            </LightGrayHeading4>
                          </Box>
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      <Box>
                        <Grid container>
                          <Grid item xs={7}>
                            <GrayHeading4 py={1}>
                              How do you like to push this audience in the
                              future ?
                            </GrayHeading4>
                            <Box py={1}>
                              <RadioGroup
                                value={schedulerUpdateReplace}
                                onChange={handleRadioChange}
                                row
                              >
                                <FormControlLabel
                                  value=""
                                  control={
                                    <Radio style={{ color: "#A4A4A4" }} />
                                  }
                                  label={
                                    <span
                                      style={{
                                        color: "#A4A4A4",
                                        fontSize: "0.875rem",
                                      }}
                                    >
                                      Push Only Once
                                    </span>
                                  }
                                />
                                {checkedState === shopifyAdsValue ||
                                checkedState === null ? null : (
                                  <FormControlLabel
                                    value="update"
                                    control={
                                      <Radio style={{ color: "#A4A4A4" }} />
                                    }
                                    label={
                                      <span
                                        style={{
                                          color: "#A4A4A4",
                                          fontSize: "0.875rem",
                                        }}
                                      >
                                        Update List
                                      </span>
                                    }
                                  />
                                )}

                                <FormControlLabel
                                  value="replace"
                                  control={
                                    <Radio style={{ color: "#A4A4A4" }} />
                                  }
                                  label={
                                    <span
                                      style={{
                                        color: "#A4A4A4",
                                        fontSize: "0.875rem",
                                      }}
                                    >
                                      Replace List
                                    </span>
                                  }
                                />
                              </RadioGroup>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            {schedulerUpdateReplace !== "" &&
                            schedulerUpdateReplace !== null ? (
                              <>
                                <GrayHeading4>Select Frequency :</GrayHeading4>
                                <Box py={1}>
                                  <CustomSelect2
                                    IconComponent={ExpandMoreIcon}
                                    renderValue={(value) => {
                                      return value === 1
                                        ? `Every Month`
                                        : `Every ${value} Months`;
                                    }}
                                    sx={{
                                      root: {
                                        border: "1px solid #DAE4ED",
                                      },
                                    }}
                                    variant={"outlined"}
                                    value={updateReplacePeriod}
                                    onChange={(e) => {
                                      setUpdateReplacePeriod(e.target.value);
                                    }}
                                  >
                                    {periodList.map((period, i) => (
                                      <MenuItem value={period} key={i}>
                                        {period}
                                      </MenuItem>
                                    ))}
                                  </CustomSelect2>
                                </Box>
                              </>
                            ) : null}
                          </Grid>
                          <Grid item xs={1} />
                        </Grid>
                      </Box>

                      <Box
                        p={1}
                        py={2}
                        pt={3}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.6rem",
                        }}
                      >
                        {checkedState === shopifyAdsValue ? (
                          <GrayHeading4>Shopify tag</GrayHeading4>
                        ) : null}

                        <Grid container>
                          <Grid item xs={7}>
                            <Grid container>
                              <Grid
                                item
                                xs={10}
                                style={{
                                  backgroundColor: listEdit
                                    ? theme.palette.common.white
                                    : "#F8FFFA",
                                  paddingLeft: "6px",
                                  border: listEdit ? "1px solid #D9D9D9" : null,
                                }}
                              >
                                <TextField
                                  disabled={!listEdit}
                                  variant="standard"
                                  id={"renameList1"}
                                  style={{ width: "100%" }}
                                  inputProps={{
                                    maxLength:
                                      checkedState === shopifyAdsValue
                                        ? 30
                                        : 60,
                                    style: {
                                      color: listEdit ? "#8F8F8F" : "#00A850",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      backgroundColor: listEdit
                                        ? theme.palette.common.white
                                        : "#F8FFFA",
                                      height: "38px",
                                    },
                                  }}
                                  sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: listEdit
                                        ? "#8F8F8F"
                                        : "#00A850",
                                    },
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                      <CustomInputAdornment
                                        position="start"
                                        style={{
                                          backgroundColor: listEdit
                                            ? theme.palette.common.white
                                            : "#F8FFFA",
                                        }}
                                      >
                                        <ListOutlined
                                          style={{
                                            color: listEdit
                                              ? "#8F8F8F"
                                              : "#00A850",
                                            fontSize: "24px",
                                          }}
                                        />
                                        <Grid
                                          item
                                          xs={6}
                                          style={{ display: "flex" }}
                                        >
                                          <Typography
                                            component={"span"}
                                            color={
                                              listEdit ? "#8F8F8F" : "#00A850"
                                            }
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: 700,
                                              marginRight: "0 !important",
                                            }}
                                          >
                                            &ensp;lxr_
                                          </Typography>
                                        </Grid>
                                      </CustomInputAdornment>
                                    ),
                                  }}
                                  onChange={(e) => {
                                    if (checkedState === shopifyAdsValue) {
                                      setShopifyTagName(e.target.value);
                                    } else {
                                      setFieldListName(e.target.value);
                                    }
                                  }}
                                  value={
                                    checkedState === shopifyAdsValue
                                      ? shopifyTagName
                                      : fieldListName
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={2}>
                                {!listEdit ? (
                                  <Button
                                    onClick={() => {
                                      handleEditList();
                                    }}
                                    style={{
                                      textTransform: "none",
                                      textDecoration: "none",
                                      fontSize: "24px",
                                      minWidth: 0,
                                      height: "50px",
                                    }}
                                  >
                                    <EditOutlined
                                      style={{
                                        fontSize: "24px",
                                        color: "#747474",
                                      }}
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      setListEdit(false);
                                      setFinalListName(fieldListName);
                                    }}
                                    style={{
                                      textTransform: "none",
                                      textDecoration: "none",
                                      fontSize: "24px",
                                      minWidth: 0,
                                      height: "50px",
                                    }}
                                  >
                                    <SaveOutlined
                                      style={{
                                        color: "#00A850",
                                        fontSize: "24px",
                                      }}
                                    />
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={2}></Grid>

                          {checkedState === shopifyAdsValue ||
                          checkedState === null ? null : (
                            <Grid item xs={3} style={{ textAlign: "right" }}>
                              <OrangeOutlinedButton
                                style={{ fontSize: "1rem" }}
                                onClick={DownloadHandler}
                              >
                                <DownloadOutlined
                                  style={{
                                    color: theme.palette.secondary.light,
                                    fontSize: "14px",
                                  }}
                                />
                                &nbsp;&nbsp; Hashed Emails
                              </OrangeOutlinedButton>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              <Box
                style={{
                  position: "fixed",
                  bottom: 0,
                  zIndex: 2,
                  width: "770px",
                  backgroundColor: "#FAFAFA",
                  border: "1px solid #FAFAFA",
                    padding: "0.8rem 1.3rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <OrangeFilledButton
                  style={{
                    fontSize: "1rem",
                    borderRadius: 0,
                    float: "right",
                    marginBottom: 1,
                    fontWeight: 500,
                  }}
                  variant="contained"
                  disabled={
                    !disablePush ||
                    push_audiences_reducer.isLoading ||
                    (checkedState === shopifyAdsValue && shopifyDataSourceObj &&
                      shopifyDataSourceObj?.write_customers === false)
                  }
                  onClick={handlePush}
                >
                  <Send
                    style={{
                      color: theme.palette.common.white,
                      fontSize: "24px",
                    }}
                  />
                  &nbsp;
                  {push_audiences_reducer.isLoading
                    ? "Pushing.."
                    : "Push Audience"}
                </OrangeFilledButton>
              </Box>
            </Box>
          ) : null
        ) : null}
      </Drawer>
      <Dialog open={openStatus} classes={{ paper: { borderRadius: 0 } }}>
        <Box style={{ width: "500px" }}>
          {push_audiences_reducer.result ? (
            push_audiences_reducer.result.length > 0 ? (
              push_audiences_reducer.result[0].status === "initialized" ||
              push_audiences_reducer.result[0].status === "started" ? (
                <Box>
                  <Box
                    style={{ backgroundColor: "#68E1FD", textAlign: "center" }}
                  >
                    {Icons.audPushSucc}
                  </Box>
                  <Box p={1} style={{ textAlign: "center" }}>
                    <Box>
                      <Typography
                        style={{ color: "#00D83A", fontSize: "1.75rem" }}
                      >
                        Push Successful
                      </Typography>
                      <Typography
                        style={{
                          color: "#747474",
                          fontSize: "1rem",
                          paddingTop: "5px",
                        }}
                      >
                        Your selected audience has been pushed successfully.
                      </Typography>
                      <br />
                      <OrangeFilledButton
                        style={{ borderRadius: 0, fontWeight: 500 }}
                        onClick={handleRedirect}
                      >
                        Click here to see the Audiences
                      </OrangeFilledButton>
                    </Box>
                  </Box>
                </Box>
              ) : push_audiences_reducer.result[0].message === nameError ? (
                <Box>
                  <Box
                    style={{ backgroundColor: "#FFF9F9", textAlign: "center" }}
                  >
                    {Icons.audDelete}
                  </Box>
                  <Box p={1} style={{ textAlign: "center" }}>
                    <Box>
                      <Typography
                        style={{ color: "#ED4A4A", fontSize: "1.75rem" }}
                      >
                        Push Failed !
                      </Typography>
                      <Typography
                        style={{
                          color: "#747474",
                          fontSize: "1rem",
                          paddingTop: "5px",
                        }}
                      >
                        List name already exists. Please rename the audience
                        list and push.
                      </Typography>
                      <br />
                      <OrangeFilledButton
                        style={{ borderRadius: 0, fontWeight: 500 }}
                        onClick={() => setOpenStatus(false)}
                      >
                        Close
                      </OrangeFilledButton>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box
                    style={{ backgroundColor: "#FFF9F9", textAlign: "center" }}
                  >
                    {Icons.audDelete}
                  </Box>
                  <Box p={1} style={{ textAlign: "center" }}>
                    <Box>
                      <Typography
                        style={{ color: "#ED4A4A", fontSize: "1.75rem" }}
                      >
                        Push Failed !
                      </Typography>
                      <Typography
                        style={{
                          color: "#747474",
                          fontSize: "1rem",
                          paddingTop: "5px",
                        }}
                      >
                        Due to some technical reason that last push didn’t go
                        well.
                      </Typography>
                      <br />
                      <Typography
                        style={{
                          color: "#747474",
                          fontSize: "1rem",
                          paddingTop: "5px",
                        }}
                      >
                        {push_audiences_reducer.result[0].message}
                      </Typography>
                      <br />
                      <OrangeFilledButton
                        style={{ borderRadius: 0, fontWeight: 500 }}
                        onClick={handlePush}
                      >
                        Try Again
                      </OrangeFilledButton>
                    </Box>
                  </Box>
                </Box>
              )
            ) : null
          ) : null}
        </Box>
      </Dialog>
      {isSuccessModalOpen?.value ? <SuccessModal /> : null}
    </React.Fragment>
  );
};

export default RemarketDialogBox;
